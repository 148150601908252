import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PostList from "../components/PostList"
import TagHeader from "../components/TagHeader"

const TagTemplate = ({ data: { tag } }) => {
  console.log(tag)
  const { posts } = tag
  return (
    <Layout>
      <Seo title={`tag: ${tag.name}`} description={tag.uri} />
      <TagHeader tag={tag} />
      <PostList posts={posts.nodes} />
    </Layout>
  )
}

export default TagTemplate

export const pageQuery = graphql`
  query TagListById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    tag: wpTag(id: { eq: $id }) {
      id
      name
      uri
      count
      posts {
        nodes {
          title
          date
          id
          excerpt
          uri
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
          tags {
            nodes {
              name
              uri
            }
          }
        }
      }
    }
  }
`
