import React from "react"
import { Tag } from "../../types/Post"
import "./index.scss"

interface Props {
  tag: Tag
}

const TagHeader = ({ tag }: Props) => {
  return (
    <h2 className="tagHeader">
      #{tag.name} <span className="count">{tag.count}</span>
    </h2>
  )
}

export default TagHeader
